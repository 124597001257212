import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import { Grid, Hidden } from '@material-ui/core';

const SkeletonSubscriptionView = () => (
        <>
        <Skeleton
          animation="wave"
          component="div"
          height={300}
          variant="rect"
          />
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
            <Grid item xs={12}>
              <Hidden xsDown>
                <Skeleton animation="wave" height={75} />
                <Skeleton animation="wave" height={25} />
              </Hidden>
              <Hidden smUp>
                <Skeleton animation="wave" height={75} />
                <div>
                  <Skeleton animation="wave" height={25} />
                  <Skeleton animation="wave" height={25} />
                  <Skeleton animation="wave" height={25} />
                </div>
              </Hidden>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton animation="wave" height={125} style={{
                marginRight: 10,
                marginLeft: 10,
              }} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton animation="wave" height={125} style={{
                marginRight: 10,
                marginLeft: 10,
              }} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton animation="wave" height={125} style={{
                marginRight: 10,
                marginLeft: 10,
              }} />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Skeleton animation="wave" height={125} style={{
                marginRight: 10,
                marginLeft: 10,
              }} />
            </Grid>
          </Grid>
        <SkeletonFooter/>
    </>
);

export default SkeletonSubscriptionView;
